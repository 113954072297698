import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { ComponentPageNav } from '~/src/components/component-page-nav';
import StatusMenu from '~/src/components/status-menu';
import { AccessibilityLabel, StatusLabel } from '@primer/gatsby-theme-doctocat';
import { Label } from '@primer/react';
import { graphql } from "gatsby";
export const query = graphql`
  query {
    primerReactVersion {
      version
    }
    allReactComponent(filter: {componentId: {eq: "action_menu"}}) {
      nodes {
        status
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Box = makeShortcode("Box");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Box style={{
      marginBottom: "24px"
    }} mdxType="Box">
  <ComponentPageNav basePath="/components/action-menu" includeReact={true} includeRails={true} includeFigma={true} current="react" mdxType="ComponentPageNav" />
    </Box>
    <Box style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "25px"
    }} mdxType="Box">
  <Box style={{
        display: "flex",
        gap: "5px"
      }} mdxType="Box">
    <Label size="large" mdxType="Label">@primer/react@v{props.data.primerReactVersion.version}</Label>
    <StatusLabel status="Deprecated" mdxType="StatusLabel" />
    <AccessibilityLabel a11yReviewed={false} short={false} mdxType="AccessibilityLabel" />
  </Box>
  <Box mdxType="Box">
    <StatusMenu currentStatus="deprecated" statuses={[...props.data.allReactComponent.nodes.map(reactComponent => reactComponent.status), "deprecated"]} parentPath="/components/action-menu/react" mdxType="StatusMenu" />
  </Box>
    </Box>
    <p>{`An `}<inlineCode parentName="p">{`ActionMenu`}</inlineCode>{` is an ActionList-based component for creating a menu of actions that expands through a trigger button.`}</p>
    <h2>{`Deprecation`}</h2>
    <p>{`Use `}<a parentName="p" {...{
        "href": "/components/action-menu/react"
      }}>{`new version of ActionMenu`}</a>{` with composable API, design updates and accessibility fixes.`}</p>
    <h3>{`Before`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ActionMenu
  anchorContent="Menu"
  onAction={fn}
  items={[
    {text: 'New file'},
    {text: 'Copy link'},
    {text: 'Edit file'},
    ActionMenu.Divider,
    {text: 'Delete file', variant: 'danger'},
  ]}
  overlayProps={{width: 'small'}}
/>
`}</code></pre>
    <h3>{`After`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ActionMenu>
  <ActionMenu.Button>Menu</ActionMenu.Button>
  <ActionMenu.Overlay width="small">
    <ActionList>
      <ActionList.Item onSelect={fn}>New file</ActionList.Item>
      <ActionList.Item>Copy link</ActionList.Item>
      <ActionList.Item>Edit file</ActionList.Item>
      <ActionList.Divider />
      <ActionList.Item variant="danger">Delete file</ActionList.Item>
    </ActionList>
  </ActionMenu.Overlay>
</ActionMenu>
`}</code></pre>
    <p>{`Or continue using deprecated API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {ActionMenu} from '@primer/react/deprecated'
`}</code></pre>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live deprecated",
        "live": true,
        "deprecated": true
      }}>{`<ActionMenu
  anchorContent="Menu"
  onAction={({text}) => console.log(text)}
  items={[
    {text: 'New file', key: 'new-file'},
    ActionMenu.Divider,
    {text: 'Copy link', key: 'copy-link'},
    {text: 'Edit file', key: 'edit-file'},
    {text: 'Delete file', variant: 'danger', key: 'delete-file'},
  ]}
/>
`}</code></pre>
    <h2>{`Example with grouped items`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live deprecated",
        "live": true,
        "deprecated": true
      }}>{`<ActionMenu
  anchorContent="Menu"
  onAction={({text}) => console.log(text)}
  groupMetadata={[
    {groupId: '0'},
    {groupId: '1', header: {title: 'Live query', variant: 'subtle'}},
    {groupId: '2', header: {title: 'Layout', variant: 'subtle'}},
    {groupId: '3'},
    {groupId: '4'},
  ]}
  items={[
    {key: '1', leadingVisual: TypographyIcon, text: 'Rename', groupId: '0'},
    {key: '2', leadingVisual: VersionsIcon, text: 'Duplicate', groupId: '0'},
    {key: '3', leadingVisual: SearchIcon, text: 'repo:github/github', groupId: '1'},
    {
      key: '4',
      leadingVisual: NoteIcon,
      text: 'Table',
      description: 'Information-dense table optimized for operations across teams',
      descriptionVariant: 'block',
      groupId: '2',
    },
    {
      key: '5',
      leadingVisual: ProjectIcon,
      text: 'Board',
      description: 'Kanban-style board focused on visual states',
      descriptionVariant: 'block',
      groupId: '2',
    },
    {
      key: '6',
      leadingVisual: FilterIcon,
      text: 'Save sort and filters to current view',
      disabled: true,
      groupId: '3',
    },
    {key: '7', leadingVisual: FilterIcon, text: 'Save sort and filters to new view', groupId: '3'},
    {key: '8', leadingVisual: GearIcon, text: 'View settings', groupId: '4'},
  ]}
/>
`}</code></pre>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`items`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ItemProps[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Required. A list of item objects conforming to the `}<inlineCode parentName="td">{`ActionList.Item`}</inlineCode>{` props interface.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`renderItem`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(props: ItemProps) => JSX.Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`ActionList.Item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If defined, each item in `}<inlineCode parentName="td">{`items`}</inlineCode>{` will be passed to this function, allowing for `}<inlineCode parentName="td">{`ActionList`}</inlineCode>{`-wide custom item rendering.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`groupMetadata`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`GroupProps[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If defined, `}<inlineCode parentName="td">{`ActionList`}</inlineCode>{` will group `}<inlineCode parentName="td">{`items`}</inlineCode>{` into `}<inlineCode parentName="td">{`ActionList.Group`}</inlineCode>{`s separated by `}<inlineCode parentName="td">{`ActionList.Divider`}</inlineCode>{` according to their `}<inlineCode parentName="td">{`groupId`}</inlineCode>{` property.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`renderAnchor`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(props: ButtonProps) => JSX.Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If defined, provided component will be used to render the menu anchor. Will receive the selected `}<inlineCode parentName="td">{`Item`}</inlineCode>{` text as `}<inlineCode parentName="td">{`children`}</inlineCode>{` prop when an item is activated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`anchorContent`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`React.ReactNode`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If defined, it will be passed to the trigger as the elements child.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onAction`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`(props: ItemProps) => void`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If defined, this function will be called when a menu item is activated either by a click or a keyboard press.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If defined, ActionMenu will use this to control the open/closed state of the Overlay instead of controlling the state internally. Should be used in conjunction with the `}<inlineCode parentName="td">{`setOpen`}</inlineCode>{` prop.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`setOpen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`(state: boolean) => void`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If defined, ActionMenu will use this to control the open/closed state of the Overlay instead of controlling the state internally. Should be used in conjunction with the `}<inlineCode parentName="td">{`open`}</inlineCode>{` prop.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      